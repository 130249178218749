
import { defineComponent, ref, onMounted, inject } from "vue";
import {
  getChooseCalculator,
  getDesignFa,
  findDesignDetail,
  getFlBuyDownData,
  getEQCBuyDownData,
} from "@/API/program";
import { ColmunsProps, ColmunsChildrenProps } from "@/API/types";
import { MaintainCellProps } from "./../types";
import { getAllBu } from "@/API/finance";
import { useStore } from "vuex";
import deepClone from "@/utils/deepClone";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import useEventbus from "@/hooks/claim/useEventbus";
import expandIcon from "@/hooks/useExpandIcon";
import {
  useVerilfy,
  useDatasource,
  useFinancing,
  useFLwithBalloon,
  useAgility,
  columnsAgility,
} from "./utils";
import BuyDownFLWithBalloon from "./FLWithBalloon.vue";
import ErrorModal from "./ErrorModal.vue";
interface StatusParams {
  typeClassCode: string;
  modelYear: string;
  model: string;
  nstGroupName: string;
  // excelBrand: string;
}

interface FlTextArrProps {
  [key: number]: {
    colTitle: string;
    itemTitle: string;
    textArr: { text: string; vehicleText: string; term: string }[];
  }[];
}

interface SaveProps {
  typeClassCode: string;
  modelYear: string;
  model: string;
  // excelBrand: string;
  sheetName: string;
  progCode: string;
  progCodeFromDate: string;
  progCodeToDate: string;
}

export default defineComponent({
  components: {
    BuyDownFLWithBalloon,
    ErrorModal,
  },
  name: "",
  setup() {
    const offerType = ref("fa");
    // 判断是否升级过
    let isUpgrade = false;
    // 显示表格1
    const isShow = ref(false);
    const store = useStore();
    const isRender = ref(true);
    const colArr = ref<string[]>([]);
    const buList = ref();

    const { checkBrandSole, getMaxWidth, checkFaDate, handleSet } =
      useVerilfy();
    // 获取表格初始
    const columns = ref<ColmunsProps[]>([]);
    const selectedKeys = ref<string[]>([]);
    const calculatorTable = ref();
    const expKeys = ref<string[]>([]);
    const originData = ref();
    const {
      getTableData,
      onExpand,
      onSelectChange,
      configColumns,
      isCheckModel,
      addChildren,
    } = useDatasource(calculatorTable, originData, expKeys, selectedKeys);

    const getParams = (type = ""): StatusParams[] => {
      const params: StatusParams[] = [];
      if (selectedKeys.value.length) {
        const arr = originData.value.filter((item: MaintainCellProps) =>
          selectedKeys.value.includes(item.id)
        );
        arr.forEach((item: MaintainCellProps, index: number) => {
          params.push({
            typeClassCode: item[100],
            modelYear: item[103],
            model: item[102],
            nstGroupName: item[101],
            // excelBrand: checkBrandSole()[0],
          });
          if (type) params[index]["programId"] = store.state.pgmData.programId;
        });
      }
      return params;
    };

    const configQuery = () => {
      const offer = [];
      if (store.state.pgmData.orderProtection) {
        offer.push("Order Protection");
      } else if (store.state.pgmData.presale) {
        offer.push("Pre Sale");
      }
      if (
        store.state.pgmData.offer &&
        Object.keys(store.state.pgmData.offer).includes("fa") &&
        store.state.pgmData.offer.fa
      ) {
        offerType.value = "fa";
      } else if (
        store.state.pgmData.offer &&
        Object.keys(store.state.pgmData.offer).includes("fa_buy_down") &&
        store.state.pgmData.offer.fa_buy_down
      ) {
        offerType.value = "buydown";
      }
      const customerArr = store.state.pgmData.customer;
      const customerType: string[] = [];
      customerArr.forEach((customer: any) => {
        customer.tableCell.forEach((cell: any) => {
          if (
            !customerType.includes(cell["Customer.type"].val) &&
            cell["Customer.type"].val
          ) {
            customerType.push(cell["Customer.type"].val);
          }
        });
      });
      const query = {
        offer: offer.join(),
        offerType: offerType.value,
        customerType: customerType.join(),
        bu: store.state.pgmData.businessUnit.selected,
      };
      const params = {
        progCode: store.state.pgmData.programId,
        offerType: offerType.value,
        bu: store.state.pgmData.businessUnit.selected,
        brand: checkBrandSole()[0],
        customerType: customerType.join(),
        offer: offer.join(),
      };
      return {
        query,
        params,
      };
    };

    const isPackUp = ref(false);

    const checkObj = ref({});
    const flComments = ref(``);
    const flTextArr = ref<FlTextArrProps>({});

    const colArrAg = ref<string[]>([]);
    const checkObjAg = ref({});
    const agTextArr = ref<FlTextArrProps>({});
    const agComments = ref("");
    const agiltyStr = ref("");

    // 生成程序Financ
    // 表格拖动 调整顺序
    const copyfinancData = ref();
    const financData = ref();
    const { getTableFinanc, configColumnsFin, dragTableSort } =
      useFinancing(copyfinancData);

    // 生产程序FL with Balloon
    const flWithBalloonData = ref();
    const {
      getTableFl,
      getTableEqFl,
      configColumnsFl,
      assignText,
      addComments,
    } = useFLwithBalloon(flTextArr, agTextArr, flComments);

    // 生成程序Agility
    const agilityData = ref();
    const agilityFlData = ref();
    const { getTableAg } = useAgility();
    const { customOn, customEmit } = useEventbus();
    // 定义Buy down的数据
    const buyFLdata = ref();
    const buyEQCdata = ref();
    // 生成可选择的计算器
    const handleShow = async () => {
      // 判断是否选择了一个brand
      if (checkBrandSole().length !== 1) {
        message.error("FA 只支持一个brand，请重新选择");
      } else {
        customEmit("updatevehicle");
        customEmit("updatecustomer");
        selectedKeys.value = [];
        expKeys.value = [];
        financData.value = null;
        flWithBalloonData.value = null;
        agilityData.value = null;
        agilityFlData.value = null;
        buyFLdata.value = null;
        buyEQCdata.value = null;
        isShow.value = true;
        isPackUp.value = false;
        // 获取计算器表格
        const { query } = configQuery();
        calculatorTable.value = [];
        const res: any = await getTableData(query, "handle");
        if (isCheckModel(res.maintain.tableCol)) {
          calculatorTable.value = addChildren(res.maintain.tableCell);
        } else {
          calculatorTable.value = res.maintain.tableCell.filter(
            (item: any) => item.id !== item.parentId
          );
        }
        columns.value = configColumns(res.maintain.tableCol);
      }
    };
    const flWithVisible = ref(false);
    const flWithErrorMsg = ref();
    // 点击按钮收起table 1
    const handlePackup = async () => {
      flTextArr.value = {};
      agTextArr.value = {};
      isPackUp.value = true;
      isUpgrade = true;
      const { query, params } = configQuery();
      if (
        Object.keys(store.state.pgmData.offer).includes("fa_buy_down") &&
        store.state.pgmData.offer.fa_buy_down
      ) {
        // 调获取fl的数据
        const { fromDate, toDate } = checkFaDate();
        const buyDownQuery = Object.assign(
          {
            startDate: fromDate,
            endDate: toDate,
          },
          params
        );
        Promise.all([
          getEQCBuyDownData(buyDownQuery, getParams()),
          getFlBuyDownData(buyDownQuery, getParams()),
        ])
          .then(([resEQC, resFl]) => {
            if (resEQC.code === "50000") {
              flWithVisible.value = true;
              flWithErrorMsg.value = resEQC.message;
              buyEQCdata.value = [];
            } else {
              buyEQCdata.value = resEQC?.filter(
                (item: any) => item?.tables.length
              );
            }
            if (resFl.code === "50000") {
              flWithVisible.value = true;
              flWithErrorMsg.value = resFl.message;
              buyFLdata.value = [];
              return Promise.reject();
            } else {
              buyFLdata.value = resFl?.filter(
                (item: any) => item?.tables.length
              );
            }
          })
          .then(async () => {
            financData.value = await getTableFinanc(getParams("financ"), query);
            dragTableSort();
          });
      } else {
        agilityFlData.value = await getTableEqFl(getParams(), query);
        flWithBalloonData.value = await getTableFl(getParams(), query);
        // flWithBalloonData.value = await getTableFl(getParams())
        agilityData.value = await getTableAg(getParams(), query);
        financData.value = await getTableFinanc(getParams("financ"), query);
        dragTableSort();
      }
    };

    // 更新当前的groupTitle
    const changeTitle = (value: string) => {
      buyFLdata.value.groupTitle = value;
    };

    // 配置columnsFin
    const columnsAg = ref<ColmunsProps[]>([]);

    // 返回当前的id集合
    const findColArr = (columns: ColmunsProps[]): string[] => {
      const colArr: string[] = [];
      const childrenArr: any[] = [];
      columns.forEach((col) => {
        for (const key in col) {
          if (key === "children") {
            childrenArr.push(col[key]);
          }
        }
      });
      childrenArr.flat().forEach((item) => {
        colArr.push(item.dataIndex);
      });
      return colArr;
    };

    //根据key 查看对应columns中的title
    const findTitle = (
      key: string,
      columnsArr: ColmunsProps[]
    ): { colTitle: string; itemTitle: string } => {
      let titleObj = {
        colTitle: "",
        itemTitle: "",
      };
      columnsArr.forEach((col: ColmunsProps) => {
        (col.children as ColmunsChildrenProps[]).forEach((item) => {
          if (item.dataIndex === key) {
            // return col
            titleObj = {
              colTitle: col.title,
              itemTitle: item.title,
            };
          }
        });
      });
      return titleObj;
    };

    // Fl 选择一个复选框后, 对应的文本域中内容变化
    // const agilityData = ref<any[]>([])

    const checkChang = (
      col: string,
      columns: any,
      record: any,
      text: string,
      index: string,
      e: Event
    ): void => {
      // const vehicleText = record[findColumnIdBytitle('Vehicle(Model Name)', columns)]
      const checked = e && (e.target as HTMLInputElement).checked;
      // debugger
      if (checked) {
        const { colTitle, itemTitle } = findTitle(col, columns);
        const hasCol = flTextArr.value[index].find(
          (item: any) =>
            item.colTitle === colTitle && item.itemTitle === itemTitle
        );
        if (hasCol) {
          hasCol.textArr.push({
            text,
            vehicleText: record[100].value,
            term: record[101].value,
          });
        } else {
          flTextArr.value[index].push({
            colTitle,
            itemTitle,
            textArr: [
              {
                text,
                vehicleText: record[100].value,
                term: record[101].value,
              },
            ],
          });
        }
        flWithBalloonData.value.tables[index].comments = assignText(
          flTextArr.value[index]
        );
      } else {
        const { colTitle, itemTitle } = findTitle(col, columns);
        const hasCol = flTextArr.value[index].find(
          (item: any) =>
            item.colTitle === colTitle && item.itemTitle === itemTitle
        );
        if (hasCol) {
          const index = hasCol.textArr.findIndex(
            (item: any) => item.text === text
          );
          hasCol.textArr.splice(index, 1);
        }
        flTextArr.value[index] = flTextArr.value[index].filter(
          (item: any) => item.textArr.length > 0
        );
        flWithBalloonData.value.tables[index].comments = assignText(
          flTextArr.value[index]
        );
      }
    };

    const saveProgram: any = inject("updataprogram");

    // 组装保存的参数
    const joinSaveParams = (data: any, isFL = false, isFinance = false) => {
      const { toDate, fromDate } = checkFaDate();
      // debugger
      let currentData = null;
      if (data) {
        if (!isFL) {
          if (isFinance) {
            if (data.length === 1 && data[0].financingDesignList.length === 0) {
              currentData = null;
            } else {
              currentData = data;
            }
          } else {
            if (data.length && !Object.keys(data).length) {
              currentData = null;
            } else {
              currentData = data;
            }
          }
        } else {
          if (data.length && data.tables.length === 0) {
            currentData = null;
          } else {
            currentData = data;
          }
        }
      }

      const params = {
        progCode: store.state.pgmData.programId,
        comments: "",
        result: {
          data: currentData,
        },
        brand: checkBrandSole()[0],
        upgrade: isUpgrade,
        progCodeFromDate: fromDate,
        progCodeToDate: toDate,
      };
      return params;
    };

    // 获取勾选数据
    const findMasterData = async () => {
      let masterData: string[] = [];
      // debugger
      return new Promise((resolve, reject) => {
        if (!isUpgrade) {
          const chooseParams = {
            programId: store.state.pgmData.programId,
          };
          getChooseCalculator({ params: chooseParams })
            .then((res) => {
              masterData = res.master;
              resolve(masterData);
            })
            .catch((e) => {
              reject(e);
            });
        } else {
          copyfinancData.value[0].paramList.forEach((item: any) => {
            masterData.push(
              item.typeClassCode +
                "&" +
                item.modelYear +
                "&" +
                item.model +
                "&" +
                item.nstGroupName
            );
          });
          resolve(masterData);
        }
      });
    };

    const configFaParams = async () => {
      const masterData: any = await findMasterData();
      const offer: string[] = [];
      if (store.state.pgmData.orderProtection) {
        offer.push("Order Protection");
      } else if (store.state.pgmData.presale) {
        offer.push("Pre Sale");
      }
      if (
        store.state.pgmData.offer &&
        Object.keys(store.state.pgmData.offer).includes("fa") &&
        store.state.pgmData.offer.fa
      ) {
        offerType.value = "fa";
      } else if (
        store.state.pgmData.offer &&
        Object.keys(store.state.pgmData.offer).includes("fa_buy_down") &&
        store.state.pgmData.offer.fa_buy_down
      ) {
        offerType.value = "buydown";
      }
      const customerArr = store.state.pgmData.customer;
      const customerType: string[] = [];
      customerArr.forEach((customer: any) => {
        customer.tableCell.forEach((cell: any) => {
          if (
            !customerType.includes(cell["Customer.type"].val) &&
            cell["Customer.type"].val
          ) {
            customerType.push(cell["Customer.type"].val);
          }
        });
      });

      return {
        offerType: offerType.value,
        bu: store.state.pgmData.businessUnit.selected,
        brand: checkBrandSole()[0],
        customerType: customerType.join(""),
        offer: offer.join(""),
        programId: store.state.pgmData.programId,
        masterData: masterData.join("#"),
      };
    };

    //  保存Fa
    const saveFa = () => {
      let flParams: any;
      let nevFlParams: any;
      let EQCParams: any;
      if (
        Object.keys(store.state.pgmData.offer).includes("fa_buy_down") &&
        store.state.pgmData.offer.fa_buy_down
      ) {
        EQCParams = joinSaveParams(buyEQCdata.value);
        flParams = joinSaveParams(buyFLdata.value);
      } else {
        EQCParams = joinSaveParams(agilityData.value);
        flParams = joinSaveParams(flWithBalloonData.value, true);
        nevFlParams = joinSaveParams(agilityFlData.value);
      }
      configFaParams().then((params) => {
        store.state.pgmData.offerType.fa = {
          faParams: params,
          financing: joinSaveParams(copyfinancData.value, false, true),
          fl: flParams,
          nevfl: nevFlParams,
          eqc: EQCParams,
        };
      });
    };

    // 监听执行
    customOn("updatefa", saveFa);
    // 更改升级的状态
    customOn("updateupgrade", () => {
      isUpgrade = false;
    });

    const router = useRouter();
    // 跳转到fa version页面
    const justToVersion = () => {
      sessionStorage.setItem("versionParams", JSON.stringify(getParams()));
      if (!store.state.pgmData.readOnly) {
        saveProgram().then(() => {
          router.push({
            path: "/calculator/version",
            query: {
              pgmId: store.state.pgmData.programId,
              bu: store.state.pgmData.businessUnit.selected,
            },
          });
        });
      } else {
        router.push({
          path: "/calculator/version",
          query: {
            pgmId: store.state.pgmData.programId,
            bu: store.state.pgmData.businessUnit.selected,
          },
        });
      }
    };

    // 调转到fa setting页面
    const justToSetting = (tableId: string, sheetName: string) => {
      saveProgram().then(() => {
        router.push({
          path: "/finance/setting",
          query: {
            programId: store.state.pgmData.programId,
            tableId,
            sheetName,
            bu: store.state.pgmData.businessUnit.selected,
            brand: checkBrandSole()[0],
          },
        });
      });
    };

    const assignData = (data: any) => {
      flWithBalloonData.value = [];
      agilityData.value = [];
      agilityFlData.value = [];
      data.forEach((res: any) => {
        if (res.sheetName === "FL with Balloon") {
          if (
            Object.keys(store.state.pgmData.offer).includes("fa_buy_down") &&
            store.state.pgmData.offer.fa_buy_down
          ) {
            buyFLdata.value = res.flDesign?.filter(
              (item: any) => item?.tables.length
            );
          } else {
            flWithBalloonData.value = res.flDesign;
            addComments(flWithBalloonData.value.tables);
          }
        }
        if (res.sheetName === "NEV FL with Balloon") {
          agilityFlData.value = res.flDesign;
        }
        if (res.sheetName === "NEV Agility") {
          agilityData.value = res.flDesign;
        }
        if (res.sheetName === "BUY DOWN EQC") {
          buyEQCdata.value = res.flDesign?.filter(
            (item: any) => item?.tables.length
          );
        }
      });
    };

    const init = () => {
      return new Promise((resolve, reject) => {
        if (store.state.pgmData.programId) {
          const { params } = configQuery();
          getDesignFa({ params })
            .then((res: any) => {
              if (res.financingDesignTableDTO.length > 0) {
                financData.value = handleSet(
                  res.financingDesignTableDTO[0].financingDesignrList
                );
                // financData.value = res.financingDesignTableDTO[0].financingDesignrList
                copyfinancData.value = deepClone(
                  res.financingDesignTableDTO[0].financingDesignrList
                );
              }
              dragTableSort();
              assignData(res.flDesignTableDTO);
              resolve("success");
            })
            .catch(() => {
              reject();
            });
        }
      });
    };

    // 版本升级。
    const isVersionUp = ref(false);
    // 确定升级
    const onVersionUp = () => {
      isUpgrade = true;
      isVersionUp.value = false;
      handlePackup();
    };
    // 取消升级
    const notVersionUp = () => {
      init();
      isVersionUp.value = false;
    };

    const mainWidth = ref<number>(0);
    onMounted(async () => {
      // 获取全部的bu
      buList.value = await getAllBu();
      let isCheck = false;
      for (const item of store.state.pgmCategory) {
        // 如果默认勾选FA 回显数据
        if (item.id === "fa" && item.selected) {
          isCheck = true;
          offerType.value = "fa";
        } else if (item.id === "fa_buy_down" && item.selected) {
          isCheck = true;
          offerType.value = "buydown";
        }
      }
      if (isCheck) {
        isShow.value = true;
        // 获取计算器表格
        if (checkBrandSole()[0]) {
          const { query } = configQuery();
          const res: any = await getTableData(query);
          if (isCheckModel(res.maintain.tableCol)) {
            calculatorTable.value = addChildren(res.maintain.tableCell);
          } else {
            calculatorTable.value = res.maintain.tableCell.filter(
              (item: any) => item.id !== item.parentId
            );
          }
          // console.log(
          //   "calculatorTable.value",
          //   calculatorTable.value,
          //   selectedKeys.value
          // );
          columns.value = configColumns(res.maintain.tableCol);
          await init();
          const params = {
            programId: store.state.pgmData.programId,
          };
          if (store.state.pgmData.programId) {
            const { isUpdate } = await findDesignDetail({ params });
            if (isUpdate) {
              isVersionUp.value = true;
            }
          }
        }
      }
      const resizeHandler = (): void => {
        const clientWidth: number = document.body.clientWidth;
        const sideNavWidth: number = (
          document.querySelector(".side-nav-left") as HTMLDivElement
        ).offsetWidth;
        const W = clientWidth - sideNavWidth;
        mainWidth.value = W;
      };
      window.addEventListener("resize", resizeHandler);
      resizeHandler();
    });

    return {
      mainWidth,
      handleShow,
      isShow,
      columns,
      calculatorTable,
      financData,

      columnsAg,
      agilityData,
      checkObjAg,
      colArrAg,
      agComments,
      agiltyStr,

      handlePackup,
      isPackUp,

      checkObj,
      checkChang,
      colArr,
      flComments,

      saveFa,
      isRender,

      justToSetting,
      justToVersion,
      selectedKeys,
      configColumnsFl,
      configColumnsFin,
      findColArr,
      isVersionUp,
      onVersionUp,
      notVersionUp,
      onSelectChange,
      checkBrandSole,
      expandIcon,
      expKeys,
      onExpand,

      getMaxWidth,
      flWithBalloonData,
      columnsAgility,
      agilityFlData,
      buyFLdata,
      buyEQCdata,
      changeTitle,
      flWithVisible,
      flWithErrorMsg,
    };
  },
});
