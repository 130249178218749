import { AutoTableCellProps, APIDataProps } from '@/types'
interface DataSourceProps extends AutoTableCellProps {
    bgcolor?: boolean;
}
// 验重
const isSameData = (data: APIDataProps, colId: string, colIndex: number) => {
    const arrSource = data.tableCell 
    const groupId = data.tableCol[colIndex].id
    const arrTarget = arrSource.filter((item: DataSourceProps, index: number) => {
        for (let i = 0; i < arrSource.length; i++) {
            if (item[colId].val === arrSource[i][colId].val && item[groupId].val === arrSource[i][groupId].val && index !== i) {
                item.bgcolor = true
                return item
            } else {
                item.bgcolor = false
            }
        }
    })
}


// 1.需要清空当前列往后列的值
export const clearCellData = (index: number, data: any, row: AutoTableCellProps): void => {
    for (let i = index + 1; i < data.length; i++) {
        // 如果有值是自动清空的，要标红
        if (row[data[i].id].constructor === Object && (row[data[i].id].val as string[]).length > 0) {
            row[data[i].id] = { val: [], isEdit: true, isAuto: true }
        }
    }
}

